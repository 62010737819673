
import { defineComponent, ref } from "vue"
import store from "@/store"
import AccountService from "@/core/services/AccountService"
import Swal from "sweetalert2/dist/sweetalert2.js"
import { UserConfig } from "@/core/interfaces/userConfig"
import UserConfigService from "@/core/services/UserConfigService"

export default defineComponent({
  name: "settings",
  emits: ["throwError"],
  data() {
    const accountNum = store.getters.activeAccountInfo.id
    return {
      monthly_emails_toggle: ref(true), // default
      current_monthly_emails_value: ref(true), // default
      accountNum,
      userConfigs: [] as UserConfig[],
      loading: ref(true)
    }
  },
  async created() {
    this.userConfigs = (await AccountService.getAccountUserConfigs(this.accountNum)).map((us) => {
      return { id: us.id, ...us.attributes }
    })
    if (this.userConfigs) {
      this.monthly_emails_toggle = this.current_monthly_emails_value = this.userConfigs.find((uCnfg) => uCnfg.subscribe_monthly_email) ? true : false
      this.loading = false
    }
  },
  methods: {
    async emailcurrent_monthly_emails_values(e) {

      const val = e
      console.log(`update account monthly email current_monthly_emails_value to ${val}`)

      let option = this.current_monthly_emails_value ? "disable" : "enable"
      let action = await Swal.fire({
        text: `Are you sure you want to ${option} all Account Users?`,
        icon: "question",
        confirmButtonText: "Ok",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-light-primary",
          cancelButton: "btn btn-danger me-3"
        }
      })

      if (action) {
        if (action.isConfirmed && option == "disable") {
          this.monthly_emails_toggle = false
          this.current_monthly_emails_value = false
          await UserConfigService.update(false, this.userConfigs)
        } else if (!action.isConfirmed && option == "enable") {
          this.monthly_emails_toggle = false
          this.current_monthly_emails_value = false
        } else if (action.isConfirmed && option == "enable") {
          this.monthly_emails_toggle = true
          this.current_monthly_emails_value = true
          await UserConfigService.update(true, this.userConfigs)
        } else {
          this.monthly_emails_toggle = true
          this.current_monthly_emails_value = true
        }
      }


    }
  }
});
